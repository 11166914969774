@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

$px-font: 'Space Grotesk', sans-serif !default;

// Theme Variable

$px-theme: #0bc257;
$px-theme-rgb: 7, 136, 255;
$px-white: #fff;
$px-black: #040c16;

$px-text: #707476;
$px-heading: #fff;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;
